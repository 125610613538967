<template>
  <b-row>
    <b-col cols="12">
      <search-view :click-search="getResponsibles"/>
    </b-col>


    <b-col cols="12">
      <b-card title="">
        <b-overlay
            variant="white"
            :show="showLoading"
            spinner-variant="primary"
            blur="0"
            opacity=".75"
            rounded="sm"
        >
          <div class="ml-1 mb-2">
            <b-row>
              <b-col cols="12" md="9"
                     class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Mostrar</label>
                <v-select
                    v-model="pageLength"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="['3','5','10','20']"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                    @input="changeLength"
                />
                <b-button variant="primary" @click="goToCreate()">
                  Añadir nuevo responsable
                </b-button>
              </b-col>
            </b-row>
          </div>

          <b-table
              ref="refUserListTable"
              class="position-relative"
              :items="responsibles"
              responsive
              striped
              sticky-header
              :fields="columns"
              primary-key="id"
              show-empty
              empty-text="No hay registros"
          >
            <template #cell(name)="data" >
              {{ data.item.name }}
            </template>

            <template #cell(actions)="data" class="col-sm-2">
              <router-link :to="{ name: 'auxiliar2_responsibles_edit', params: { id: data.item.id} }">
                <feather-icon
                    :id="`invoice-row-${data.item.id}-send-icon`"
                    icon="EditIcon"
                    class="cursor-pointer"
                    size="16"
                />

              </router-link>

              <feather-icon
                  :id="`invoice-row-${data.item.id}-preview-icon`"
                  icon="TrashIcon"
                  size="16"
                  class="cursor-pointer ml-1 text-red"
                  @click="deleteResponsibles(data.item.id)"
              />
              <b-tooltip
                  title="Eliminar Responsable"
                  :target="`invoice-row-${data.item.id}-preview-icon`"
              />
            </template>
          </b-table>

          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Mostrando {{ pageLength }} de {{ totalRecords }} responsables</span>
              </b-col>

              <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                    :value="1"
                    :total-rows="totalRecords"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="changePage"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {BRow, BCol, BTable, BCard, BButton, BTooltip, BPagination, BOverlay, BBadge} from 'bootstrap-vue'
import vSelect from "vue-select"
import {mapState} from "vuex"
import Swal from "sweetalert2"
import {GEI_AUXILIAR2_RESPONSIBLES_CREATE} from "@/router/web/constants"
import helpers from "@/resources/helpers/helpers"
import SearchView from "@/views/gei/auxiliars2_responsibles/list/components/searchView.vue"
import helper from "@/resources/helpers/helpers"

export default {
  name: "ListView",
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    BButton,
    BTooltip,
    BPagination,
    BOverlay,
    SearchView,
    vSelect,

  },
  data() {
    return {
      page: 1,
      pageLength: 10,
      totalRecords: 10,
      columns: [
        {key: 'name', label: 'Nombre', sortable: false},
        {key: 'actions', label: 'Acciones', sortable: false},
      ],
      showLoading: false,
      helpers
    }
  },
  computed: {
    ...mapState('auxiliaries2Responsibles', ['responsibles', 'search']),
  },
  created() {
    this.getResponsibles()
  },
  methods: {
    can(permission) {
      return this.$store.getters.can(permission)
    },
    formatDate(date) {
      const dateFormated = new Date(date)

      return dateFormated.toLocaleDateString("es-ES")
    },
    getResponsibles() {
      this.showLoading = true

      this.$store.dispatch('auxiliaries2Responsibles/searchResponsibles', {
        per_page: this.pageLength,
        page: this.page,
        filter: this.search,
      })
          .then(response => {
            this.showLoading = false
            this.totalRecords = response.total_records

          })
          .catch(error => {
            this.showLoading = false
            console.log(error)
          })
    },
    deleteResponsibles(id) {
      Swal.fire({
        title: "Estás seguro que quieres eliminar este responsable?",
        text: "Una vez borrada no se podra recuperar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Sí, borrar!",
        cancelButtonText: "Cancelar",
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('auxiliaries2Responsibles/deleteResponsibles', {id: id})
              .then(response => {
                this.showLoading = false
                helper.showToast('Responsable eliminado correctamente!', 'CheckIcon', 'success')
                this.getResponsibles()
              })
              .catch(error => {
                this.showLoading = false
                helper.showToast('Error al eliminar el responsable', 'AlertOctagonIcon', 'danger')
                console.log(error)
              })
        }
      })
    },
    changePage(page) {
      this.page = page
      this.getResponsibles()
    },
    changeLength(length) {
      this.length = length
      this.getResponsibles()
    },
    searchUser() {
      this.getResponsibles()
    },
    goToCreate(){
      this.$router.push(GEI_AUXILIAR2_RESPONSIBLES_CREATE)
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>